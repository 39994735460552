import React from "react"
import WebsiteLayout from "../../components/layout/website/website-layout/website-layout"
import SEO from "../../services/seo"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./index.module.scss"
import auth from "../../services/auth"
import util from "../../services/util"

// const host = process.env.GATSBY_FEDIA_DMS_HOST || "localhost"
// const port = process.env.GATSBY_FEDIA_DMS_PORT || 1337
// const baseUrl = `https://${host}:${port}/`

// const baseUrl = process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
//   `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
//   `http://${process.env.GATSBY_FEDIA_DMS_HOST}/`;

const LinksPage = () => {
  const data = useStaticQuery(graphql`
    query PageLinksQuery {
      allStrapiLinks {
        nodes {
          links {
            thumbnail
            label
            url
          }
        }
      }
    }
  `)
  console.log("Links" + data.allStrapiLinks.nodes[0])
  return (
    <WebsiteLayout>
      <SEO title='Links' />
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.title}>
            <h1>Links</h1>
            <p>Related links in forest industry and partners</p>
          </div>
          <ul className={styles.links}>
            {data.allStrapiLinks.nodes[0].links.map(l => {
            return (
              <li key={l.url}>
                <div className={styles.thumbnail}>
                  {/* NOTE: this image is a placeholder */}
                  <img
                    src={`${util.baseUrl()}${l.thumbnail}`}
                    alt={l.label}
                  />
                </div>
                <a href={l.url}>{l.label}</a>
              </li>
            )})}
          </ul>
        </div>
      </div>
    </WebsiteLayout>
  )
}

export default LinksPage
